import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Product from 'components/Product'

const ProductPage = ({ pageContext, data }) => {
  const product = {
    image: data.image.nodes[0].childImageSharp.gatsbyImageData,
    ...pageContext.product,
  }

  return (
    <Layout
      pageTitle={pageContext.title}
      backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
    >
      <oma-grid-row class='row'>
        <Product product={product} breadcrumb={pageContext.breadcrumb} />
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  query ($productId: String!) {
    imageBackground: file(
      relativePath: { eq: "cookies-cream-serveret-pa-fat.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
    image: allFile(
      filter: {
        relativeDirectory: { eq: "products" }
        name: { eq: $productId }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 505, quality: 60, layout: CONSTRAINED)
        }
      }
    }
  }
`

export default ProductPage
